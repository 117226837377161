@import 'src/assets/sass/variables';
@import '../ButtonClassNames';

.#{$ovex-classname-buttonbar} {
  display: flex;

  &-separator {
    // .ovex-ButtonBar-separator
    margin: 2px 8px 2px 0;
    border-color: ovex-darken($ovex-app-background-colour, 24%);
    border-style: solid;
    border-width: 0;
    border-left-width: $ovex-border-width;

    &:first-child, &:last-child {
      display: none;
    }
  }

  &--align {
    &-center {
      // .ovex-ButtonBar--align-center
      justify-content: center;
    }
    &-right {
      // .ovex-ButtonBar--align-right
      justify-content: flex-end;
    }
  }

  &--formControls {
    // .ovex-ButtonBar--formControls
    padding: 8px 4px;
    .uu5-bricks-button {
      //margin: 4px 4px 0 4px;
      min-width: 96px;
    }
  }

}

.#{$ovex-classname-button}, .#{$ovex-classname-dropdown} {
  margin-right: 8px;
}