@import 'src/assets/sass/variables';

@import '../../../assets/sass/colors';

.ovex-pg-PriceGuaranteeReportTable {

  &-row--commission.ag-row.ag-row-level-0 {
    // .ovex-pg-PriceGuaranteeReportTable-row--commission
    $local-bg-color: ovex-darken($ovex-app-background-colour, 6%);
    background-color: $local-bg-color;
    &.ag-row-hover {
      background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
    }
  }

  &-cell-amount--negative {
    // .ovex-pg-PriceGuaranteeReportTable-cell-amount--negative
    color: $ovex-pg-colour-negative-amount;
  }

}
