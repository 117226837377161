@import 'src/assets/sass/variables';

.ovex-dealer-renderer {
  display: flex;

  .icon {
    margin-right: 4px;
    display: flex;
    align-items: center;
    color: gray;
  }

  .dealer-name-not-active {
    color: gray
  }

  &-text {
    //.ovex-dealer-renderer-text
    overflow: hidden;
    text-overflow: ellipsis;

    &-new-dealer {
      //.ovex-dealer-renderer-text-new-dealer
      color: $ovex-colour-orange;
    }
  }


}