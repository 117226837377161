@import 'src/assets/sass/variables';

.ovex-forms-week-picker {

  .uu5-forms-label {
    font-size: $ovex-form-input-label-font-size;
  }

  .uu5-forms-message {
    font-size: $ovex-form-input-message-font-size;
    text-align: $ovex-form-input-message-text-align;
  }

  &.color-schema-green {

    .uu5-forms-input-form-item {
      color: $ovex-form-input-color;
    }

    &:not(.uu5-forms-input-read-only) .uu5-forms-input-form-item {
      // editable input
      background: $ovex-form-input-background-color-editable !important;

      &.uu5-common-bg-style-underline:focus {
        border-bottom-width: $ovex-form-input-underline-width-active;
      }
    }
    &.uu5-forms-input-read-only .uu5-forms-input-form-item {
      // read only input
      background: $ovex-form-input-background-color-readonly !important;
    }

    .uu5-common-bg-style-underline:not(.uu5-forms-input-error) {
      border-color: $ovex-form-input-underline-color !important;
    }
  }

  .uu5-bricks-calendar-month-table {
    .uu5-bricks-calendar-active,
    .uu5-bricks-calendar-day.uu5-bricks-calendar-active ~ .uu5-bricks-calendar-day {
      .uu5-bricks-calendar-range-selection {
        // set week bg color
        background-color: $skoda-primary-colour-skoda-green;
        color: $skoda-primary-colour-white;
        font-weight: bold;
      }
    }
  }

}
