@import 'src/assets/sass/variables';

.ProductionCorridorTable {

  .ProductionCorridorTableHeader {
    display: flex;

    .uu5-forms-label {
      text-align: left;
      display: flex;
      align-items: center;
      width: 50%;
    }
  }

  .ProductionCorridorTableHeaderBalance {
    width: 50%;
    display: flex;
    align-items: center;
  }

  .ovex-ag-grid {
    margin-top: 24px;
  }

  &-column { // .ProductionCorridorTable-column
    &--modified { // .ProductionCorridorTable-column--modified
      background: $radial-gradient-modified;
    }

  }

  & &-row { // .ProductionCorridorTable-row

    &--model-group { // .ProductionCorridorTable-row--model-group
      font-weight: bold;
    }

    &--limitation { // .ProductionCorridorTableTable-row--limitation
      .ProductionCorridorTable-column--modelGroup {
        padding-left: 24px;
      }
    }

    &--addedLimitation { // .ProductionCorridorTable-row--addedLimitation
      font-weight: bold;
      color: $ovex-colour-green;
    }

    &--archivedFlag { // .ProductionCorridorTable-row--archivedFlag
      font-weight: bold;
      color: $ovex-colour-red;
    }

    &--newLimitation { // .ProductionCorridorTable-row--newLimitation
      font-weight: bold;
      color: $ovex-colour-orange;
    }

  }
}
