@import 'src/assets/sass/variables';

.ovex-pg-PriceGuaranteeReportExport {

  .ovex-pg-PriceGuaranteeReportFilter,
  .ovex-pg-PriceGuaranteeReportExportButtonBar {
    margin-bottom: $ovex-horizontal-space;
  }

  .ovex-pg-PriceGuaranteeReportExportFilter .uu5-forms-label {
    height: 27px;
  }
}
