@import 'skoda-colours';
@import 'colour-functions';

$ovex-colour-white: $skoda-primary-colour-white;
$ovex-colour-gray-f8: #f8f8f8;
$ovex-colour-gray-f0: #f0f0f0;
$ovex-colour-gray-e6: #e6e6e6;
$ovex-colour-gray-d8: #d8d8d8;
$ovex-colour-gray-cc: #cccccc;
$ovex-colour-gray-c5: #c5c5c5;
$ovex-colour-gray-b4: #b4b4b4;
$ovex-colour-gray-75: #757575;
$ovex-colour-gray-55: #555555;
$ovex-colour-gray-46: #464646;
$ovex-colour-black: $skoda-primary-colour-black;

$ovex-colour-lightgray: #D3D3D3; // css name = lightgray
$ovex-colour-gray: #808080; // css name = gray
$ovex-colour-lightblue: #ADD8E6; // css name = lightblue
$ovex-colour-lightgreen: #90EE90; // css name = lightgreen
$ovex-colour-green: #008000; // css name = green
$ovex-colour-yellow: #FFFF00; // css name = yellow
$ovex-colour-yellow2: ovex-lighten($ovex-colour-yellow, 32%);
$ovex-colour-orange: #FFA500; // css name = orange
$ovex-colour-orange2: ovex-lighten($ovex-colour-orange, 32%);
$ovex-colour-red: #FF0000; // css name = red

// background colours
$ovex-bg-colour-lightblue-tr50: ovex-transparentize($ovex-colour-lightblue, 50%);
$ovex-bg-colour-lightgreen-tr50: ovex-transparentize($ovex-colour-lightgreen, 50%);
$ovex-bg-colour-green-tr50: ovex-transparentize($ovex-colour-green, 50%);
$ovex-bg-colour-yellow2-tr50: ovex-transparentize($ovex-colour-yellow2, 50%);
$ovex-bg-colour-orange2-tr50: ovex-transparentize($ovex-colour-orange2, 50%);
$ovex-bg-colour-red-tr50: ovex-transparentize($ovex-colour-red, 50%);

// Application
$ovex-base-colour: #f0f0f0;

$ovex-app-background-colour: $ovex-base-colour;
$ovex-app-name-colour: ovex-darken($ovex-base-colour, 66.6667%);

// Footer
$ovex-footer-background-colour: ovex-darken($ovex-base-colour, 17.2549%);

// ag-Grid
$ovex-ag-grid-hover-darken: 6%;
$ovex-ag-grid-cell-background-editable: $ovex-colour-white;
$ovex-ag-grid-cell-background-editable-hover: ovex-transparentize($ovex-colour-white, 56%);
$ovex-ag-grid-cell-background-invalid: ovex-transparentize($ovex-colour-red, 40%);
$ovex-ag-grid-cell-background-invalid-hover: ovex-transparentize($ovex-colour-red, 40%);

$ovex-skoda-green-lighten-64: ovex-lighten($skoda-primary-colour-skoda-green, 64%);
$ovex-skoda-green-lighten-40: ovex-lighten($skoda-primary-colour-skoda-green, 40%);
$ovex-skoda-green-lighten-08: ovex-lighten($skoda-primary-colour-skoda-green, 8%);
