@import 'src/assets/sass/variables';
@import '../DealerContracts.variables';

.ovex-BEVAnnualTargetSalesTable {

  .ag-center-cols-clipper {
    min-height: unset !important;
  }

  .ag-row-level-0{
    $local-bg-color: ovex-darken($ovex-app-background-colour, 16%);
    background-color: $local-bg-color;
    &.ag-row-hover {
      background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
    }
  }

  & &-cell-period { // .ovex-BEVAnnualTargetSalesTable-cell-period
    text-align: right;
    padding: 0 24px;
  }

  & &-cell-numeric { // .ovex-BEVAnnualTargetSalesTable-cell-numeric
    background-color: $ovex-dealer-contracts-rcp-background-color;
    text-align: right;
    padding-right: 60px;
  }
}

