@import 'src/assets/sass/variables';

.ovex-pg-PriceGuaranteeItemPublishedTable {

  &-column {
    // .ovex-pg-PriceGuaranteeItemPublishedTable-column

    &--previous {
      // .ovex-pg-PriceGuaranteeItemPublishedTable-column--previous
      background-color: $ovex-skoda-green-lighten-64;
    }
    &--current {
      // .ovex-pg-PriceGuaranteeItemPublishedTable-column--current
      background-color: $ovex-skoda-green-lighten-40;
    }
  }

  .ag-cell {
    font-family: monospace;
  }

  &-cell {
    &--code {
      // .ovex-pg-PriceGuaranteeItemPublishedTable-cell--code
      letter-spacing: 0.8px;
    }
  }

}
