@import "src/assets/sass/variables";

.ovex-UserImitateModal {
  width: 100%;
  height: 85%;
  font-size: 14px;
}

.ovex-ImitateUserModalBar {
    margin-top: 16px;
    margin-bottom: 16px;

}

.ovex-userDetailInfo{
  li {
    position: relative;
    left: -2em;
  }
}