@import 'src/assets/sass/variables';

.ovex-check-box-renderer {

  &-icon {
    display: flex;
    justify-content: center;
  }

  .uu5-forms-input-m.uu5-forms-checkbox .uu5-forms-right-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  &-modified{
    background: $radial-gradient-modified-5-8;
  }

  .color-schema-green.uu5-forms-checkbox.uu5-common-bg-style-outline:not(.uu5-common-disabled):not(.uu5-forms-input-read-only).uu5-forms-checkbox-checked .uu5-forms-checkbox-button:not(.uu5-bricks-switch) {
    border-color: $skoda-primary-colour-skoda-green;
    background-color: $ovex-base-colour;
    border-radius: 3px;
  }

  .color-schema-green.uu5-forms-checkbox.uu5-common-bg-style-outline:not(.uu5-common-disabled):not(.uu5-forms-input-read-only) .uu5-forms-checkbox-button:not(.uu5-bricks-switch) {
    border-color: $skoda-primary-colour-skoda-green;
    background-color: $ovex-base-colour;
    border-radius: 3px;
  }

  .uu5-forms-checkbox.uu5-common-disabled .uu5-forms-right-wrapper .uu5-bricks-icon {
    color: $skoda-primary-colour-black;
    border-radius: 3px;
  }
  .uu5-forms-checkbox.uu5-common-disabled .uu5-forms-right-wrapper .uu5-bricks-button:not(.uu5-bricks-switch){
    border-color: $skoda-primary-colour-black;
    border-radius: 3px;
  }

}