@import 'src/assets/sass/variables';

.ovex-pvt-ModelGroupProductionCorridorForm {

  .ovex-forms-checkbox {
    + .ovex-forms-checkbox {
      margin-top: 0;
    }
  }

}