@import 'src/assets/sass/variables';

.ovex-form-QuarterPeriodNumber {
    .ovex-forms-number {
      margin: 0;
    }

  .number-label {
    font-size: $ovex-form-input-label-font-size;
    text-align: left;
  }

  .toolTip-color {
    color: #43A047;
  }

}
