@import 'src/assets/sass/variables';
@import '../../../assets/sass/colors';

.ovex-DealerBEVAnnualTargetSalesTable {

  .ag-center-cols-clipper {
    min-height: unset !important;
  }

  .ag-row-level-0 {
    font-weight: bold;
    $local-bg-color: ovex-darken($ovex-app-background-colour, 16%);
    background-color: $local-bg-color;
    &.ag-row-hover {
      background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
    }
  }

  & &-cell-period { // .ovex-DealerBEVAnnualTargetSalesTable-cell-period
    text-align: right;
    padding-right: $ovex-ag-grid-cell-padding-header-menu;
  }

  & &-cell-numeric { // .ovex-DealerBEVAnnualTargetSalesTable-cell-numeric
    text-align: right;
    padding-right: $ovex-ag-grid-cell-padding-header-menu;

    &--editable { // .ovex-DealerBEVAnnualTargetSalesTable-cell-numeric--editable
      &:before {
        content: "\F3EB";
        position: absolute;
        left: 8px;
      }
    }

    &--modified { // .ovex-DealerBEVAnnualTargetSalesTable-cell-numeric--modified
      background: $radial-gradient-modified;
    }

  }
}

