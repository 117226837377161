@import 'src/assets/sass/variables';

@import '../ValidationResultColors';

.ovex-pg-ValidationResultPGItemTable {

  .ag-cell {
    font-family: monospace;
  }

  &-cell {
    &--code {
      // .ovex-pg-ValidationResultPGItemTable-cell--code
      letter-spacing: 0.8px;
    }
    &--commission {
      // .ovex-pg-ValidationResultPGItemTable-cell--commission
      background-color: ovex-transparentize($ovex-colour-gray-75, 50%);
      font-weight: bold;
    }
  }

  &-validationResult {
    // .ovex-pg-ValidationResultPGItemTable-validationResult

    &--sent {
      // .ovex-pg-ValidationResultPGItemTable-validationResult--sent
      background-color: $ovex-pg-validation-result-sent;
    }
    &--ok {
      // .ovex-pg-ValidationResultPGItemTable-validationResult--ok
      background-color: $ovex-pg-validation-result-ok;
    }
    &--repairable {
      // .ovex-pg-ValidationResultPGItemTable-validationResult--repairable
      background-color: $ovex-pg-validation-result-repairable;
    }
    &--notRepairable {
      // .ovex-pg-ValidationResultPGItemTable-validationResult--notRepairable
      background-color: $ovex-pg-validation-result-not-repairable;
    }
    &--error {
      // .ovex-pg-ValidationResultPGItemTable-validationResult--error
      background-color: $ovex-pg-validation-result-error;
    }
  }

  .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
    padding-top: 56px;
  }

  &.ovex-ag-grid .ag-ltr .ag-row-group-leaf-indent {
    margin-left: 0;
  }

}
