@import '../../../../../assets/sass/variables';

.ovex-NavMenu {
  height: 100%;

  .uu5-bricks-dropdown-button-cover {
    height: 100%;
  }

  .uu5-bricks-dropdown-button {
    border-top: transparent solid 4px;
    border-bottom: transparent solid 4px;
    background-color: transparent;
  }

  &--active {
    // .ovex-NavMenu--active
    .uu5-bricks-dropdown-button {
      border-bottom-color: $skoda-primary-colour-skoda-green !important;
    }
  }

}