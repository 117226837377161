@import 'src/assets/sass/variables';

.ovex-UserDetailInfoNotFound {
  width: 720px;
  margin: 24px auto;
  padding: 16px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.54) !important;

  &-icon {
    // .ovex-UserDetailInfoNotFound-icon
    margin-right: 16px;
  }

  &-message {
    // .ovex-UserDetailInfoNotFound-message
    p:first-child {
      margin-top: 0;
    }
    p {
      margin: 8px 0;
    }
    ul {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}
