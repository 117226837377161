@import 'src/assets/sass/variables';

.ovex-dealer-config-button-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .general-setting {
    font-size: 22px;
    margin-bottom: 4px;
  }

  .effectiveness-label {
    display: flex;
    align-items: center;

    &-text{
      margin-left: 8px;
      padding-top: 1px;
      font-size: 13px;
    }
  }

  .legend {
    display: flex;
    color: gray;
    .vertical-line {
      border-left: 1px solid grey;
      height: 95%;
      margin: 0 10px;
    }

    .legend-item {
      display: flex;

      &-text {
        margin-left: 4px;
      }

      .uu5-forms-right-wrapper {
        justify-content: center;
        display: flex;
        padding: 0;
      }

      .uu5-bricks-column {
        justify-content: center;
        display: flex;
        padding: 0;
      }
      .uu5-forms-checkbox {
        margin: 0
      }

      .uu5-bricks-text {
        align-content: center;
        font-size: 12px;
        color: gray;
      }

      .color-schema-green.uu5-forms-checkbox.uu5-common-bg-style-outline:not(.uu5-common-disabled):not(.uu5-forms-input-read-only).uu5-forms-checkbox-checked .uu5-forms-checkbox-button:not(.uu5-bricks-switch) {
        border-color: $skoda-primary-colour-skoda-green;
        background-color: $ovex-base-colour;
        border-radius: 3px;
      }
      .color-schema-green.uu5-forms-checkbox.uu5-common-bg-style-outline:not(.uu5-common-disabled):not(.uu5-forms-input-read-only) .uu5-forms-checkbox-button:not(.uu5-bricks-switch){
        border-color: $skoda-primary-colour-skoda-green;
        background-color: $ovex-base-colour;
        border-radius: 3px;
      }

      .uu5-forms-checkbox.uu5-common-disabled .uu5-forms-right-wrapper .uu5-bricks-icon {
        color: $skoda-primary-colour-black;
        border-radius: 3px;
      }

      .uu5-forms-checkbox.uu5-common-disabled .uu5-forms-right-wrapper .uu5-bricks-button:not(.uu5-bricks-switch) {
        border-color: $skoda-primary-colour-black;
        border-radius: 3px;
      }

    }
  }

}