@import 'colours';

// Font
$skoda-font-family-name: 'SKODA Next';

$ovex-font-family-set: $skoda-font-family-name, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$ovex-font-weight-light: 300;
$ovex-font-weight-black: 900;
$ovex-font-color: $ovex-colour-black;
$ovex-font-size: 15px;

// Application
$ovex-border-radius: 2px;
$ovex-border-style: solid;
$ovex-border-width: 1px;
$ovex-horizontal-space: 16px;

// Nav bar
$ovex-navbar-height: 60px;

// Footer
$ovex-footer-height: 40px;

// Dot of modified value
$radial-gradient-modified: radial-gradient(circle at 5px 5px, $ovex-colour-orange 1px, transparent 3px) no-repeat;
$radial-gradient-modified-5-8: radial-gradient(circle at 5px 8px, $ovex-colour-orange 1px, transparent 3px) no-repeat;

// Forms
$ovex-form-input-height: 32px;
$ovex-form-input-label-font-size: 12px;
$ovex-form-input-message-font-size: 10px;
$ovex-form-input-message-text-align: right;
$ovex-form-input-color: $ovex-font-color;
$ovex-form-input-background-color-editable: ovex-lighten($ovex-app-background-colour, 80%);
$ovex-form-input-background-color-readonly: ovex-darken($ovex-app-background-colour, 6%);
$ovex-form-input-underline-color: ovex-darken($ovex-app-background-colour, 50%);
$ovex-form-input-underline-width-active: 2px;
$ovex-form-input-underline-width-error: 1px;

// AG-Grid
$ovex-ag-grid-cell-padding-header-menu: 28px;
