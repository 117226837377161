@import 'src/assets/sass/variables';

.ovex-NegotiationCreationModalForm {

  .uu5-bricks-modal-xl {
    width: min(1570px, 98%);
  }

  .uu5-bricks-modal-body {
    min-height: 460px;
  }
}
