@import '../../../assets/sass/colors';

.ovex-DealerContractsATSPeriodsViewTable {

  &-column {
    // .ovex-DealerContractsATSPeriodsViewTable-column

    &-header-total {
      // .ovex-DealerContractsATSPeriodsViewTable-column-header-total
      background-color: $ovex-annual-target-column-statistics-bg-color;
      color: $ovex-annual-target-column-statistics-color;
    }

    &-header-group-odd {
      // .ovex-DealerContractsATSPeriodsViewTable-column-header-group-odd
      background-color: $ovex-annual-target-column-group-odd-bg-color;
    }

    &-working {
      // .ovex-DealerContractsATSPeriodsViewTable-column-working
      background-color: $ovex-annual-target-column-working-bg-color;
    }
    &-approved {
      // .ovex-DealerContractsATSPeriodsViewTable-column-approved
      background-color: $ovex-annual-target-column-approved-bg-color;
    }
    &-statistics {
      // .ovex-DealerContractsATSPeriodsViewTable-column-statistics
      background-color: $ovex-annual-target-column-statistics-bg-color;
      color: $ovex-annual-target-column-statistics-color;
    }

    &-cell.ag-cell {
      // .ovex-DealerContractsATSPeriodsViewTable-column-cell
      text-align: right;
      padding-right: 32px;
    }
  }

  &-single-branch-dealer {
    // .ovex-DealerContractsATSPeriodsViewTable-single-branch-dealer
    &.ag-row {
      $local-bg-color: ovex-darken($ovex-app-background-colour, 8%);
      background-color: $local-bg-color;
      &.ag-row-hover {
        background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
      }
    }
  }

}