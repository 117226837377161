@import 'src/assets/sass/variables';

$home-info-box-shadow: 0 0 40px 2px $ovex-colour-gray;

.ovex-home {
  background: no-repeat center/cover url('../../assets/images/home.jpg');

  & > &-info { // .ovex-home-info
    background-color: rgba(230, 230, 230, 0.9);
    width: 32%;
    margin-top: 25%;
    margin-left: 8%;
    padding: 24px;
    border-radius: 4px;
    box-shadow: $home-info-box-shadow
  }
}
