@import "assets/sass/variables.scss";

@font-face{
  font-family: $skoda-font-family-name;
  src: url("assets/fonts/SKODA_Next-Light.woff") format("woff");
  font-weight: $ovex-font-weight-light;
}
@font-face{
  font-family: $skoda-font-family-name;
  src: url("assets/fonts/SKODA_Next-LightItalic.woff") format("woff");
  font-weight: $ovex-font-weight-light;
  font-style: italic;
}
@font-face {
  font-family: $skoda-font-family-name;
  src: url("assets/fonts/SKODA_Next-Regular.woff") format("woff");
}
@font-face {
  font-family: $skoda-font-family-name;
  src: url("assets/fonts/SKODA_Next-Italic.woff") format("woff");
  font-style: italic;
}
@font-face {
  font-family: $skoda-font-family-name;
  src: url("assets/fonts/SKODA_Next-Bold.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: $skoda-font-family-name;
  src: url("assets/fonts/SKODA_Next-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: $skoda-font-family-name;
  src: url("assets/fonts/SKODA_Next-Black.woff") format("woff");
  font-weight: $ovex-font-weight-black;
}
@font-face {
  font-family: $skoda-font-family-name;
  src: url("assets/fonts/SKODA_Next-BlackItalic.woff") format("woff");
  font-weight: $ovex-font-weight-black;
  font-style: italic;
}

body {
  margin: 0;
  font-family: $ovex-font-family-set;
  //color: $ovex-font-color;
  //font-size: $ovex-font-size;
}
