@import 'src/assets/sass/variables';
@import 'src/assets/sass/colours';


.ovex-pg-PriceGuaranteeCreationModalForm {

    //ovex-pg-PriceGuaranteeCreationModalForm-assigned-pg
    &-assigned-pg {
      background-color: $ovex-colour-gray-d8 !important;
      opacity: 0.5;
  }

}
