@import 'src/assets/sass/variables';

.ovex-forms-checkbox {

  .uu5-forms-label {
    font-size: 12px;
  }

  .uu5-forms-input-label-right {
    .uu5-bricks-icon {
      right: initial;
    }
  }

  &.uu5-forms-input-read-only .uu5-bricks-switch-on .uu5-bricks-switch-icon {
    background-color: $ovex-colour-gray-55 !important;
  }

  &.uu5-forms-input-m.uu5-forms-checkbox-type-switch {
    .uu5-bricks-switch {
      height: 20px;
      width: 30px;
      min-width: 30px;

      .uu5-bricks-switch-icon {
        font-size: 14px;
      }
    }
  }

}
