@import 'src/assets/sass/variables';

.Restrictions {
  display: flex;
  flex-direction: column;

  .RestrictionsTable {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .ovex-ag-grid {
      flex-grow: 1;
    }
  }

  .ovex-RestrictionsFilter {
    margin-bottom: $ovex-horizontal-space;
  }

}