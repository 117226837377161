@import 'src/assets/sass/variables';

.ovex-ConfirmModal {

  .uu5-bricks-modal-dialog {
    margin-top: $ovex-navbar-height * 2;
    background: $ovex-app-background-colour;

  }
  .uu5-bricks-modal-body {
    background: $ovex-app-background-colour;
  }
  .ovex-Button--secondary {
    background: $ovex-app-background-colour !important;
  }

}