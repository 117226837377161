@import 'src/assets/sass/variables';
@import '../../assets/sass/colors';

.ovex-DealerContracts {

  .ovex-DealerContractsFilter, .ovex-DealerContractsButtonBar {
    margin-bottom: $ovex-horizontal-space;
  }

  &-column {
    // .ovex-DealerContracts-column

    &-working {
      // .ovex-DealerContracts-column-working
      background-color: $ovex-annual-target-column-working-bg-color;
    }
    &-approved {
      // .ovex-DealerContracts-column-approved
      background-color: $ovex-annual-target-column-approved-bg-color;
    }
    &-statistics {
      // .ovex-DealerContracts-column-statistics
      background-color: $ovex-annual-target-column-statistics-bg-color;
      color: $ovex-annual-target-column-statistics-color;
    }
  }

}
