@import '../../../assets/sass/variables';

.ovex-UserMenu {
  height: 100%;
  margin: 0 0 0 8px;

  .uu5-bricks-dropdown-button-cover {
    height: 100%;
  }

  .uu5-bricks-dropdown-button {
    padding: 0;
    border-top: transparent solid 4px;
    border-bottom: transparent solid 4px;
    background-color: transparent;

    .uu5-bricks-button-text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &-language {
    padding: 0 16px 0 16px;

    .uu5-bricks-dropdown-button {
      padding: 0 16px 0 16px;
    }
  }
}