@import 'src/assets/sass/variables';

.ovex-pvt-BalanceRecalculationModalForm {
  .hidden {
    visibility: hidden;
  }

  .ovex-forms-checkbox {
    + .ovex-forms-checkbox {
      margin-top: 0;
    }
  }

  .ovex-pvt-BalanceRecalculationForm {
    .ovex-pvt-BalanceRecalculationForm-balanceApplication {
      display: flex;

      .ovex-pvt-balanceLabel {
        width: 50%;
        display: flex;
        align-items: center;
        text-align: left;
      }

      .ovex-pvt-half {
        width: 50%;
      }

      .period {
        padding-left: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}