@import 'src/assets/sass/variables';

.ovex-form-QuarterComponentForm {
  margin-top: 24px;
  .uu5-forms-input-wrapper {
    .ovex-forms-number, .ovex-forms-checkbox {
      margin: 0;
    }
  }

  .label {
    font-size: $ovex-form-input-label-font-size;
    text-align: left;
  }

  .hidden {
    visibility: hidden;
  }

}
